@use "../../../app.scss" as s;

#blog-post {
  #blog-container {
    display: flex;
    flex-direction: column;
    gap: s.$xl;
  }

  .page-body {
    display: flex;
    flex-direction: column;
    gap: calc(s.$s * 2);

    .title-container {
      h4 {
        margin-bottom: s.$xs;
      }

      .date-text {
        font-size: 0.75rem; // 12px
        line-height: 1.5rem;
        color: s.$medium-light-gray;
      }
    }

    .post-image {
      width: 100%;
      height: 22vw;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
      }
    }

    p {
      margin: 0;
    }

    a {
      font-size: 0.875rem; // 14px
    }

    blockquote {
      border-left: 5px solid #cdb980;
      margin: 0;
      padding: 0 30px 0 30px;
      font-style: italic;
      font-size: 0.875rem; // 14px
      line-height: 1.7em;
      font-family: Georgia, Times, "Times New Roman", serif;
    }

    .smaller-text {
      font-size: 1rem;
    }

    .list-item {
      font-size: 0.75rem;
    }
  }

  .links-item {
    display: flex;
    align-items: center;
    gap: s.$s;
    font-style: italic;

    img {
      width: s.$m;
      height: s.$m;
    }
  }

  @media only screen and (max-width: 768px) {
    .post-image {
      height: 35vw;
    }
  }
}