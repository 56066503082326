@use "../../../../app.scss" as s;

#project-item {
  position: relative;
  flex: 25%;
  max-width: 25%;
  padding-top: 17%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: s.$medium-brown;
    width: 100%;
    height: 100%;
    z-index: 9;
    cursor: pointer;
    opacity: 0;

    .overlay-text {
      display: flex;
      flex-direction: column;;
      padding: s.$m;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      gap: s.$s;
      overflow: hidden;

      .divider {
        height: 1px;
        display: block;
        width: s.$m;
        border-top: 1px solid s.$white;
      }

      h5 {
        font-size: 1rem;
      }

      h5, p {
        margin: 0;
        line-height: 1.2;
        color: s.$white;
      }
    }

    &:hover {
      opacity: 0.9;
      -webkit-animation: fadeInFromNone 0.5s ease-out;
      -moz-animation: fadeInFromNone 0.5s ease-out;
      -o-animation: fadeInFromNone 0.5s ease-out;
      animation: fadeInFromNone 0.5s ease-out;
    }
  }
}

@-webkit-keyframes fadeInFromNone {
  0% {
      opacity: 0;
  }

  1% {
      opacity: 0;
  }

  100% {
      opacity: 0.9;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
      opacity: 0;
  }

  1% {
      opacity: 0;
  }

  100% {
      opacity: 0.9;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
      opacity: 0;
  }

  1% {
      opacity: 0;
  }

  100% {
      opacity: 0.9;
  }
}

@keyframes fadeInFromNone {
  0% {
      opacity: 0;
  }

  1% {
      opacity: 0;
  }

  100% {
      opacity: 0.9;
  }
}

@media only screen and (max-width: 1100px) {
  #project-item {
    max-width: 33.3%;
    flex: 33.3%;
    padding-top: 22%;
  }
}

@media only screen and (max-width: 768px) {
  #project-item {
    flex: 50%;
    max-width: 50%;
    padding-top: 34%;
  }
}

@media screen and (max-width: 360px) {
  #project-item {
    flex: 100%;
    max-width: 100%;
    padding-top: 68%;
  }
}