@font-face {
  font-family: 'Noto Sans Display';
    src: url('NotoSansDisplay-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans Display';
    font-weight: bold;
    src: url('NotoSansDisplay-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans Display';
    font-weight: 500;
    src: url('NotoSansDisplay-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans Display';
    font-style: italic;
    src: url('NotoSansDisplay-Italic.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans Display';
    font-weight: light;
    src: url('NotoSansDisplay-Light.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Raleway';
    src: url('Raleway-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Raleway';
    font-weight: bold;
    src: url('Raleway-SemiBold.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Raleway';
    font-weight: 500;
    src: url('Raleway-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
}
