@use "../../app.scss" as s;

#portfolio {
  margin-top: 14px;
  margin-bottom: -(s.$xl);
  margin-top: -(s.$xl);

  .tag-banner {
    display: inline-flex;
    gap: s.$xs;
    align-items: center;
    background-color: s.$medium-brown;
    width: calc(100% - s.$m * 2);
    margin: 0;
    padding: s.$m;
    list-style-type: none;
    color: s.$white;
    flex-wrap: wrap;

    p {
      margin: 0;
      line-height: 1;
    }

    li {
      padding: s.$xs s.$s;
      border-radius: 4px;

      &:hover, &.selected {
        background: rgba(0,0,0, .1);
        cursor: 'pointer';
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #portfolio {
    margin-top: 0;
    margin-bottom: 0;
  }
}
