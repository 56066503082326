@use "../../app.scss" as s;

#styled-content {
  .content {
    position: relative;
    background-color: s.$white;
    float: none;
    max-width: 770px;
    margin: 0 40px;
    box-shadow: 0 1px 1px s.$selected-background;

    .page-head {
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 75px;
      border-bottom: 1px solid s.$lighter-gray;

      h4 {
        margin-bottom: 0;
      }

      .links-to {
        display: inline-flex;
        gap: calc(s.$s * 2);
        
        p {
          margin: 0;
          font-size: .75rem;
        }
      }
    }

    .page-body {
      padding: 30px;
      border-bottom: 1px solid s.$lighter-gray;
    }
  }
}

@media screen and (max-width: 768px) {
  #styled-content {
    .content {
      max-width: 100%;
      margin: 0;
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 600px) {
  #styled-content {
    .content {
      .page-head {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  #styled-content {
    .content {
      .page-head {
        .links-to {
          display: flex;
          flex-direction: column;
          gap: 0;
        }
      }

      .page-body {
        padding: 20px 15px 20px;
      }
    }
  }
}
