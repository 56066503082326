@use "../../../app.scss" as s;

#portfolio-page {
  .video-wrapper {
    background-color: black;
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
      
    video {
      min-width: 100%;
      max-height: 100%;
      margin: auto;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      font-size: 18px;
      padding: 10px;
      border: none;
      background: rgba(0,0,0,0.7);
      color: s.$lighter-blue;
      cursor: pointer;
      margin: s.$m;
      z-index: 110;
    
      &:hover {
        background: s.$dark-blue;
      }
    }
  }

  .video-active {
    display: flex
  }

  .not-clickable-image, .clickable-image {
    background-color: var(--content-background-color);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .clickable-image img {
    cursor: pointer;
  }

  .page-body {
    display: flex;
    flex-direction: column;
    gap: s.$l;
  }

  .awssld {
    --loader-bar-color: rgba(0,0,0,.3);
  }

  .awssld__controls button {
    background-color: rgba(0,0,0,.7);
    width: 32px;
    height: 32px;
    padding: 0;
    top: calc(50% - (0.5 * 32px));

    .awssld__controls__arrow-right, .awssld__controls__arrow-left {
      --organic-arrow-height: 10px;
      --organic-arrow-color: white;
      --organic-arrow-thickness: 3px;
      opacity: 1;
    }
  }

  .portfolio-page-info {
    display: flex;
    gap: s.$l;

    .col {
      width: 50%;
    }

    p {
      margin-bottom: 0;
    }
  }

  .skill-list {
    display: flex;
    gap: s.$xs;

    .skill-tag {
      display: inline-block;
      background-color: s.$medium-gray;
      border-radius: 3px;
      padding: 5px 7px 5px;
      color: s.$white;
    }
  }

  .links-item {
    display: flex;
    align-items: center;
    gap: s.$s;
    font-style: italic;

    img {
      width: s.$m;
      height: s.$m;
    }
  }
}

@media screen and (max-width: 600px) {
  #portfolio-page {
    .portfolio-page-info {
      flex-direction: column;

      .col {
        width: 100%;
      }
    }
  }
}