@use "../../../App.scss" as s;

#project-gallery {
  overflow: hidden;
  height: 100%;

  .masonry {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
  }
}