// main
$lighter-blue: #6df7f5;
$light-blue: #54c6c4;
$medium-blue: #72aaaa;
$dark-blue: #0e4e4e;
$ultra-dark-blue: #0d2726;

// secondary
$medium-brown: #cdb980;
$dark-brown: #6a6a6a;

// gray scale
$white: #ffffff;
$ultra-light-gray: #fafafa;
$super-light-gray: #eeeeee;
$lighter-gray: #e0e0e0;
$medium-light-gray: #b0b0ad;
$light-gray: #8f8f8f;
$medium-gray: #7d7d7b;
$dark-gray: #555555;
$darker-gray: #2e2e2e;

// other
$inverted-links: rgba(255,255,255,.7);
$selected-background: rgba(0,0,0, .1);
$input-border: 1px solid #ddd;
$warning: #dc3232;