@use "../../app.scss" as s;

#blog {
  #blog-container {
    display: flex;
    flex-direction: column;
    gap: s.$xl;
  }

  .page-body {
    display: flex;
    flex-direction: column;
    gap: calc(s.$s * 2);

    .title-container {
      h4 {
        margin-bottom: s.$xs;
      }

      .date-text {
        font-size: 0.75rem; // 12px
        line-height: 1.5rem;
        color: s.$medium-light-gray;
      }
    }

    .post-image {
      width: 100%;
      height: 22vw;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
      }
    }

    p {
      margin: 0;
    }

    a {
      font-size: 0.875rem;
    }

    .teaser-text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
              line-clamp: 4; 
      -webkit-box-orient: vertical;
    }
  }

  @media only screen and (max-width: 768px) {
    .post-image {
      height: 35vw;
    }
  }
}