@use "../../../app.scss" as s;

#image-header-reference {
  position: relative;

  .hidden-to-get-height {
    visibility: hidden;
  }

  .background-image {
    position: absolute;
    z-index: 2;
    left: -320px;
    top: 0;
    bottom: 0;
    width: 100vw;
    background-image: url('../../../styles/images/FlowerPortfolio1.jpg');
    background-size: cover;
    background-repeat:   no-repeat;
    background-position: center right;
  }

  .body-image-over {
    position: absolute;
    z-index: 30;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .body-image-over, .hidden-to-get-height {
    .body-boxes {
      display: flex;
      gap: 30px;

      .left-info-banner {
        background-color: s.$medium-blue;
        -ms-flex-preferred-size: calc(40% - 18px);
        flex-basis: calc(40% - 18px);
        max-width: calc(40% - 18px);
        margin-top: 20px;
        text-align: center;
        padding: 20px;
        
        img {
          width: 100%;
          border-radius: 50%;
        }
        h5 {
          color: s.$ultra-dark-blue;
          margin: 20px 0;
          font-size: 22px;
          text-transform: uppercase;
        }
        button {
          display: block;
          padding: 15px;
        }
        h6 {
          color: s.$ultra-dark-blue;
          line-height: 1.25;
          font-size: 1.17em;
        }
      }

      .inner-text {
        padding: 20px 0;
        max-width: 340px;
        color: s.$white;
        text-align: left;
        overflow-wrap: break-word;

        h5 {
          color: s.$white;
          margin: 20px 0;
          font-size: 22px;
          text-transform: uppercase;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #image-header-reference {
    .background-image {
      left: -30px;
    }
    .body-image-over, .hidden-to-get-height {
      .body-boxes {
        flex-direction: column;
        gap: 0;

        .inner-text {
          margin: 0 0 20px;
        }

        .left-info-banner, .inner-text {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  #image-header-reference {
    .background-image {
      left: -15px;
    }
  }
}
