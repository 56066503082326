@use "../../app.scss" as s;

#home {
  .home-inner-content {
    @include s.content;
  }

  .home-inner-container {
    @include s.container;

    &__centered {
      @include s.container;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      // margin: 0 auto;

      p, button {
        margin-bottom: 30px;
      }

      .home-inner-text {
        max-width: 290px;
        margin: 0 auto;
      }
    }

    h1 {
      max-width: calc(50% - 15px);
      margin-right: 30px;
    }
  }

  .home-kpi-container {
    display: flex;
    gap: 30px;
    padding-bottom: 50px;

    .home-kpi {
      text-align: center;
      display: flex;
      flex-direction: column;
      overflow-wrap: break-word;
      width: 29%;

      .home-kpi-image {
        margin: 0 auto;
        width: 100px;
        height: 100px;

        img {
          height: 100%;
          overflow: hidden;
          object-fit: cover;
        }
      }

      h2 {
        margin: 30px 0 15px;
      }
    }
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  #home {
    .home-inner-container {
      display: block;

      h1 {
        margin-right: 0;
        max-width: 100%;
      }
    }

    .home-kpi-container {
      display: block;
  
      .home-kpi {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  .body-image-over {
    top: 106px;

    .body-image-text {
      top: calc(50% - 45px);
      right: 0;
      left: 320px;
      justify-content: left;
  
      .inner-text {
        max-width: 90%;
        margin: 0 30px;
      }
    }
  }
}
