@import 'styles/style';

body {
  font-family: "Noto Sans Display", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $medium-gray;
  background-color: $lighter-gray;
  font-size: 16px;
  font-weight: 500;
}

.App {
  width: 100vw;
}

p, h1, h2, h3, h4, a {
  margin-top: 0;
}

h1 {
  font-size: 2.813rem; // 45px
  line-height: 1;
  font-family: 'Raleway';
  font-weight: 600;
  color: $dark-gray;
  text-transform: uppercase;
}

h2 {
  font-size: 2rem; // 32px
  line-height: 1;
  font-weight: 500;
  color: $dark-gray;
  text-transform: uppercase;
}

h3 {
  font-size: 1.5rem; // 24px
  line-height: 1;
  font-weight: 500;
  color: $dark-gray;
  text-transform: capitalize;
  margin: 0 0 15px;
}

h4 {
  font-size: 1.125rem; // 18px
  line-height: 1.2;
  font-weight: 500;
  color: $dark-gray;
  margin-bottom: $m;
}

h5 {
  font-size: 0.875rem; // 14px
  line-height: 1;
  font-weight: bold;
}

h6 {
  font-size: 0.875rem; // 14px
  line-height: 1.1rem;
  font-weight: 500;
  color: $medium-blue;
  margin: 0 0 15px 0;
}

p {
  font-size: 0.875rem; // 14px
  line-height: 1.4rem;
}

button {
  font-size: 1rem; // 16px
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  color: $medium-blue;
  border: 2px solid $medium-blue;
  background-color: $white;
  padding: 15px 45px;
  transition: all .2s ease-in-out;
  margin: 0 auto;
  width: 100%;

  &.inverted {
    border-color: $white;
    color: $white;
    background-color: transparent;

    &:hover {
      color: $medium-blue;
      border-color: $white;
      background-color: $white;
      cursor: pointer;
    }
  }

  &:hover {
    color: $white;
    background-color: $medium-blue;
    cursor: pointer;
  }
}

nav a {
  font-size: .75rem; // 12px
  line-height: 25px;
  font-weight: bold;
  color: $light-gray;
  text-decoration: none;
  outline: none;

  &:hover {
    color: $darker-gray;
  }

  &:active, &:focus, &.active {
    color: $darker-gray;
  }
}

a {
  color: $medium-blue;
  text-decoration: none;
  font-size: 100%;
  line-height: 1.7em;
  font-weight: 500;

  &:hover {
    color: $light-blue;
  }

  &:active, &:focus, &.active {
    color: $light-blue;
  }
}

@mixin content {
  margin-top: 55px;
}

@mixin container {
  display: flex;
  padding: 100px 0 50px;
  overflow-wrap: break-word;
}

@media screen and (max-width: 768px) {
  @mixin content {
    margin-top: 55px;
  }

  @mixin container {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2rem; // 32px
  }
  
  h2 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 360px) {
  p {
    font-size: .75rem; // 12px
    line-height: 1.2rem;
  }
}
