@use "../app.scss" as s;

#layout {
  position: relative;

  header {
    position: fixed;
    width: 250px;
    height: 100%;
    background: s.$white;
    -webkit-box-shadow: 0 1px 1px s.$selected-background;
    -moz-box-shadow: 0 1px 1px s.$selected-background;
    box-shadow: 0 1px 1px s.$selected-background;
    z-index: 100;
    line-height: 25px;

    .inside {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px 35px 20px 40px;
      height: calc(100% - 60px);
      gap: 10px;

      nav {
        display: flex;
        flex-direction: column;
      }

      .header-top {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: scroll;
        margin-bottom: 80px;

        h5 {
          padding-top: 10px;
        }
      }

      .logo-container {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
        }
      }

      .social-media-icons {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        margin-right: 15px;
    
          .social-media-icon {
            height: 32px;
            cursor: pointer;
          }
      }
    }
  }

  .top-header {
    display: none;
  }

  .layout-body {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: s.$medium-blue;
    width: auto;
    margin-left: 250px;
    height: 100%;
    min-height: calc(100vh - 80px);
    padding-top: 40px;
    padding-bottom: 40px;

    .follow-me {
      display: none;
    }
  }

  footer {
    display: none;
  }

  nav {
    display: none;
    width: 0;
    svg {
      display: none;
    }
  }

  .header-bottom {
    position: absolute;
    bottom: 0;
    left: 40px;
    right: 40px;
    background-color: s.$white;
    color: s.$light-gray;
    padding-top: 20px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  #layout {
    .show-nav-navigation {
      width: 0;
    }

    nav {
      position: fixed;
      left: auto;
      top: 0;
      right: 0;
      width: 250px;
      height: 100%;
      background: s.$white;
      z-index: 100;
      padding: 40px 20px;
      background-color: s.$darker-gray;
      color: s.$white;
      overflow: auto;
      transition: 200ms;
      display: flex;
      flex-direction: column;
      -o-transition: .5s;
      -ms-transition: .5s;
      -moz-transition: .5s;
      -webkit-transition: .5s;
      transition: .5s;

      a {
        margin: 0 20px 15px;
        color: s.$inverted-links;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
        cursor: pointer;
        font-size: 100%;
        font-weight: 500;
        line-height: 1;
      }

      svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 30px;
        margin-top: -16px;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
        opacity: .5;
      }
    }

    header {
      display: none;
    }

    .top-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: s.$white;
      border-bottom: 1px solid s.$lighter-gray;
      padding: 20px 20px 15px 20px;

      .logo-container {
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          overflow: hidden;
        }
      }

      .burger-menu {
        height: 20px;
        width: 23px;
        cursor: pointer;
        fill: s.$dark-gray;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
      }
    }

    .layout-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin-left: 0;
      margin-right: 250px;
      padding-top: 0;
      padding-bottom: 0;

      .follow-me {
        display: block;
        background-color: s.$white;
        padding: 40px 30px;

        .social-media-icons {
          display: flex;
          gap: 15px;
          flex-wrap: wrap;
          margin-right: 15px;
      
            .social-media-icon {
              height: 32px;
            }
        }
      }
    }

    .nav-body, .show-nav-top-header {
      transform: translateX(-290px);
      transition: 200ms;
    }

    footer {
      display: block;
      background-color: s.$super-light-gray;
    }
    
    .header-bottom {
      position: static;
      background-color: transparent;
      text-align: center;
      padding: 20px 15px;
      height: unset;

      p {
        margin: 0;
      }

      &.nav-bottom {
        margin-right: 290px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  #layout {
    nav {
      width: calc(100% - 40px);
    }

    .layout-body {
      .follow-me {
        padding: 40px 15px;
      }
    }
  }
}
