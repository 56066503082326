.animation {
  animation: zoom-in-zoom-out 1s ease 1;

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.05, 1.05);
    }
    100% {
      transform: scale(1, 1);
    }
  }
}

#testimonials {
  display: flex;
  gap: 30px;

  .testimonial {
    position: relative;
    background-image: url('./apostrophe.png');
    background-repeat: no-repeat;

    &__inner {
      padding: 30px 30px 0;
    }
  }
}

@media screen and (max-width: 768px) {
  #testimonials {
    display: block;
    // flex-direction: column;
  }
}
